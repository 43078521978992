<template>
  <div>
    <v-card class="ma-2">
      <div class="d-flex justify-space-between px-4">
        <v-card-title>Scoring report</v-card-title>
        <v-btn class=" my-auto primary" @click="getScoringsExcel()"
               :disabled="isLoading">
          Download Excel
          <v-icon v-if="isLoading" class="ml-2">mdi-loading mdi-spin</v-icon>
          <v-icon v-else
                  right
                  dark
          >
            mdi-cloud-download
          </v-icon>
        </v-btn>
      </div>
      <!-- Filter -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>

          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-autocomplete
                    v-model="scoringReportFilter.clientId"
                    :items="scoringClients"
                    item-text="name"
                    item-value="id"
                    label="Clients"
                    @change="getScoringReport(1)"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                    v-model="scoringReportFilter.methodId"
                    :items="scoringMethods"
                    item-text="name"
                    item-value="id"
                    label="Methods"
                    @change="getScoringReport(1)"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-select
                    v-model="scoringReportFilter.scoringStatus"
                    :items="scoringStatus"
                    label="Scoring status"
                    item-text="name"
                    item-value="id"
                    @change="getScoringReport(1)"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                    v-model="scoringReportFilter.emitterType"
                    :items="emitterType"
                    label="Emitter type"
                    item-text="name"
                    item-value="id"
                    @change="getScoringReport(1)"
                ></v-select>
              </v-col>


              <v-col>
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Select date range"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        @click:clear="scoringReportFilter.endDate = null, scoringReportFilter.startDate = null, getScoringReport(1)"
                        v-bind="attrs"
                        v-on="on"

                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="saveDate(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-btn
                @click="getScoringReport(1)"
                color="primary"
            >Search
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card class="ma-2 pa-4">
      <v-card-text>
        <v-data-table
            :loading="isLoading"
            :loading-text="'Loading...'"
            :headers="headers"
            :items="scoringReportList"
            :items-per-page="scoringReportFilter.count"
            hide-default-footer
        >
          <template v-slot:item.emitterType="{item}">
            <div>
              <v-chip
                  color="primary"
                  v-if="item.emitterType === 0"
              >
                Uzcard
              </v-chip>
              <v-chip
                  color="secondary"
                  v-else-if="item.emitterType === 1"
              >
                Humo
              </v-chip>
              <v-chip
                  color="error"
                  v-else
              >
                Unknown
              </v-chip>
            </div>
          </template>
          <template v-slot:item.status="{item}">
            <div>
              <v-chip
                  color="primary"
                  v-if="item.status === 0"
              >
                Created
              </v-chip>
              <v-chip
                  color="success"
                  v-else-if="item.status === 1"
              >
                Success
              </v-chip>
              <v-chip
                  color="error"
                  v-else-if="item.status === 2"
              >
                Failed
              </v-chip>
              <v-chip
                  color="warning"
                  v-else-if="item.status === 3"
              >
                Unknown
              </v-chip>
            </div>
          </template>
          <template v-slot:item.requestStart="{item}">
            <div>{{ item.requestStart | dateTimeFormat }}</div>
          </template>
          <template v-slot:item.requestStop="{item}">
            <div>{{ item.requestStop | dateTimeFormat }}</div>
          </template>
          <template v-slot:item.log="{item}">
              <v-btn @click="openLog(item.log)" icon color="info" small v-if="item.log">
                <v-icon small> mdi-eye</v-icon> show
              </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="text-center" v-if="scoringReportList.length > 0">
        <v-pagination
            v-model="scoringReportFilter.page"
            :length="totalCount"
            :total-visible="7"
        ></v-pagination>
      </div>
    </v-card>
    <div class="text-center">
      <v-dialog
          v-model="logModal"
          width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Log:
          </v-card-title>


          <v-card-text>
           <v-simple-table dense>
            <template v-slot:default>
              <tbody>
              <div v-for="(pair, index) in logs" :key="index">
                <span class="red--text">Log: №{{ index + 1 }}</span><br>
                <span v-for="(section, sectionName) in pair" :key="sectionName">
                        {{ sectionName }}:<pre>{{ section }}</pre>
                      </span>
                <hr/>
              </div>
              </tbody>
            </template>
          </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                text
                @click="logModal = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ReportPage',
  data() {
    return {
      headers: [
        {text: 'id', value: 'id', align: 'center', sortable: false},
        {text: 'ClientID', value: 'clientId', align: 'center', sortable: false},
        {text: 'Login', value: 'login', align: 'center', sortable: false},
        {text: 'Emitter Type', value: 'emitterType', align: 'center', sortable: false},
        {text: 'Status', value: 'status', align: 'center', sortable: false},
        {text: 'Card Number', value: 'cardNumber', align: 'center', sortable: false},
        {text: 'Request Start', value: 'requestStart', align: 'center', sortable: false},
        {text: 'Request Stop', value: 'requestStop', align: 'center', sortable: false},
        {text: 'Log', value: 'log', align: 'center', sortable: false},
      ],
      scoringReportFilter: {
        count: 20,
        page: 1,
        clientId: null,
        startDate: null,
        endDate: null,
        methodId: null,
        emitterType: null,
        scoringStatus: null,
        // methodGroupId: 0
      },
      scoringMethods: [],
      scoringClients: [],
      scoringClientsCopy: [],
      dates: null,
      totalCount: 1,
      scoringReportList: [],
      transactionModal: false,
      update: false,
      isLoading: false,
      panel: 0,
      date: [],
      menu: false,
      modal: false,
      menu2: false,
      transactionData: [],
      logModal: false,
      logBody: '',
      logs: [],
      scoringStatus: [
        {id: null, name: 'All'},
        {id: 0, name: 'Created'},
        {id: 1, name: 'Success'},
        {id: 2, name: 'Failed'},
        {id: 3, name: 'Unknown'},
      ],
      emitterType: [
        {id: null, name: 'All'},
        {id: 0, name: 'Uzcard'},
        {id: 1, name: 'Humo'},
      ]
  }
  },
  methods: {
    async getScoringReport(page) {
      if (page == 1) {
        this.scoringReportFilter.page = 1;
      }
      try {
        this.isLoading = true;
        this.scoringReportList = []
        const res = await this.$http.post('Report/getScoringReport', this.scoringReportFilter);
        if (!res.error) {
          this.scoringReportList = res.result.items
          this.totalCount = parseInt(res.result.allCount / this.scoringReportFilter.count)
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    async getScoringsExcel() {
      try {
        this.isLoading = true;
        const res = await this.$http.post('Report/getScoringsExcel', this.scoringReportFilter);
        if (!res.error) {
          console.log(res.result.base64String)
          this.downloadBlobFile(this.base64toBlob(res.result.base64String), `excelReport.xlsx`);
        }
      } catch (e) {
        this.errorNotification(e.response.data.error.errorMessage)
      } finally {
        this.isLoading = false
      }
    },
    async getScoringMethodsList() {
      try {
        const res = await this.$http.get('Report/getScoringMethodsList')
        this.scoringMethods = res.result.items
        this.scoringMethods.unshift({id: null, name: "All"})
      } catch (e) {
        this.errorNotification(e.response.data.error.message)
      }
    },
    async getScoringClientsList() {
      try {
        const res = await this.$http.get('Report/getScoringClientsList')
        this.scoringClients = res.result.items
        this.scoringClients.unshift({id: null, name: "All"})
        // console.log(res.result);
      } catch (e) {
        this.errorNotification(e.response.data.error.message)
      }
    },
    saveDate(date) {
      this.$refs.menu.save(date);
      this.scoringReportFilter.startDate = date[0];
      this.scoringReportFilter.endDate = date[1];
      this.getScoringReport();
    },
    parseDataString(dataString) {
      const sections = dataString.split(/\n+/);
      const logs = [];
      let currentLog = null;

      for (const line of sections) {
        try {
          const matches = line.match(/^(.*?):\s*(\{.*\})/);

          if (matches && matches.length === 3) {
            const sectionName = matches[1].toLowerCase();
            const jsonData = matches[2];

            if (
                sectionName === "checkrequest" ||
                sectionName === "performrequest" ||
                sectionName === "request"
            ) {
              // Create a new log object for "checkRequest", "performRequest", or "request"
              currentLog = {[sectionName]: JSON.parse(jsonData)};
            } else if (
                sectionName === "checkresponse" ||
                sectionName === "performresponse" ||

                sectionName === "response"
            ) {
              // Add "checkResponse", "performResponse", or "response" to the current log object
              currentLog[sectionName] = JSON.parse(jsonData);
              logs.push(currentLog);
              currentLog = null; // Reset the current log
            }
          } else if (currentLog && line.trim() !== "") {
            // Append lines to the current section if not empty
            const sectionKey = Object.keys(currentLog)[0];
            currentLog[sectionKey] += `\n${line}`;
          }
        } catch (error) {
          console.error("Error parsing JSON: ", error);
        }
      }

      // Now 'logs' contains an array of objects, each representing a set of paired sections
      this.logs = logs
    },
    openLog(item) {
      this.logs = [];
      this.parseDataString(item);
      this.logModal = true
    }
  },
  created() {
    this.getScoringMethodsList()
    this.getScoringClientsList()
    this.getScoringReport();

  },
  watch: {
    'scoringReportFilter.page': {
      handler: function (val, oldVal) {
        this.getScoringReport();
      },
      deep: true
    },
  }
}
</script>
